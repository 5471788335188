// import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
// import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

// s4n
// @see: https://github.com/gatsbyjs/gatsby/issues/1875
// import { Location } from '@reach/router'
// import { globalHistory } from "@reach/router"
import $ from "jquery";



const TawkTo = (props) => {

    const { children, intl, serviceId } = props

    const serviceLangugeEndpointList = {
        // Soft4Net
        pl: 'https://embed.tawk.to/5ccf163ad07d7e0c6392052e/1daeid8a1',
        en: 'https://embed.tawk.to/5ccf163ad07d7e0c6392052e/default',
    };

    // Declare a new state variable, which we'll call "language"
    const [locale, setLocale] = useState(intl.locale);

    /**
     * @see: https://reactjs.org/docs/hooks-effect.html
     * Same as componentDidMount() hook because we pass an empty array [] as second argument,
     * this argument watches for changes in passed arguments
     * In componentDidMount we have access to window object elsewhere not always!!!
     */
    useEffect(() => {
        setLocale(intl.locale)
        loadScripts();
    }, [intl.locale]);

    const loadScripts = async () => {
        if (typeof serviceLangugeEndpointList[locale] === `undefined`) {
            return false;
        }

        $(window).on('load', function() {
            // console.log(locale)

            let serviceLangugeEndpoint = serviceLangugeEndpointList[locale];
    
            const Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
                let s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                s1.src=serviceLangugeEndpoint;
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
            })();            
        });
    }

    // return nothing to be rendered
    return null;
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default injectIntl(TawkTo)
